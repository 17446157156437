import React from "react"
import { Paper, Theme, makeStyles, Box } from "@material-ui/core"
import { PlayCircleFilled } from "@material-ui/icons"

export interface Props {
  imageUrl: string
  title: string
  onClick: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  videoItem: {
    textAlign: "center",
    overflow: "hidden",
    cursor: "pointer",
    position: "relative",
    "&:hover $playIcon": {
      fontSize: "48px",
    },
  },
  videoItemInner: {
    height: "205px",
    width: "100%",
    overflow: "hidden",
  },
  videoItemImage: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    maxWidth: "100%",
  },
  playIcon: {
    background: "#fff",
    padding: "1px",
    borderRadius: "100%",
    position: "absolute",
    top: "calc(50% - 20px)",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "60px",
    transition: ".2s all ease-in-out",
  },
}))

const VideoItem = ({ imageUrl, title, onClick }: Props) => {
  const classes = useStyles()

  return (
    <Paper elevation={3} className={classes.videoItem} onClick={onClick}>
      <div className={classes.videoItemInner}>
        <img title={title} src={imageUrl} className={classes.videoItemImage} />
      </div>
      <PlayCircleFilled color="primary" className={classes.playIcon} />
      <Box p={1}>
        <small>{title}</small>
      </Box>
    </Paper>
  )
}

export default VideoItem
