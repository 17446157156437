import React, { useState } from "react"
import { Grid, Typography, Box } from "@material-ui/core"
import FsLightbox from "fslightbox-react"

import { youtubeUrl, youtubeImageUrl, stringToAnchorId } from "../utils"

import SEO from "../components/seo"
import VideoItem from "../components/video-item"

// @ts-ignore
import videos from "../../data/videos.yaml"

interface Video {
  title: string
  youtube_id: string
}
export interface VideoEntry {
  category: string
  items: Video[]
}

const VideosPage = () => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  const openLightbox = (index: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index + 1,
    })
  }

  const youtubeUrls = videos
    .map((entry: VideoEntry) =>
      entry.items.map((video: Video) => youtubeUrl(video.youtube_id))
    )
    .reduce((a: Video[], b: Video[]) => a.concat(b), [])

  const items = (entry: VideoEntry) =>
    entry.items.map((item: Video, index: number) => {
      return (
        <Grid
          item
          md={3}
          key={"VideoItem-" + entry.category + index + item.youtube_id}
          style={{ width: "100%" }}
        >
          <VideoItem
            title={item.title}
            imageUrl={youtubeImageUrl(item.youtube_id)}
            onClick={() =>
              openLightbox(youtubeUrls.indexOf(youtubeUrl(item.youtube_id)))
            }
          />
        </Grid>
      )
    })

  const section = (entry: VideoEntry) => (
    <section
      id={stringToAnchorId(entry.category)}
      key={"VideoSection-" + entry.category}
    >
      <Typography variant="subtitle1" gutterBottom>
        {`${entry.category} (${entry.items.length})`}
      </Typography>
      <Grid container spacing={1}>
        {items(entry)}
      </Grid>
    </section>
  )

  return (
    <>
      <SEO title="Videos" />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box fontWeight="fontWeightBold">
          <Typography variant="h3" gutterBottom color="primary">
            Videos
          </Typography>
        </Box>
      </Box>
      {videos.map((entry: VideoEntry) => (
        <Box mb={6} key={entry.category}>
          {section(entry)}
        </Box>
      ))}
      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={youtubeUrls}
      />
    </>
  )
}

export default VideosPage
